footer
{
    background-color: #4db5ff56;
    padding : 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;

}

.footer__logo
{
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}

.footer__links
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap:2rem;
    font-size: 1.2rem;
    margin:0 auto 3rem;
    
}

.footer__socials
{
    display: flex;
    justify-content: center;
    gap:1rem;
    margin-bottom: 3rem;
}

.footer__socials a
{
    padding: 0.8rem;
    font-size: 2.3rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;

}
.footer__copyright
{
    margin-bottom: 3rem;
    font-size: 1.2rem;
}

/* ===========IMG +++++++++++++++ */

.img-me
{
    background: linear-gradient(rgb(188, 247, 244), transparent);
    width: 22rem;
    height: 30rem;
    position:absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding-top: 2rem;
}

/*============== scroll down ================*/

.scoll_down
{
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 200;
    font-size: 0.9rem;
}

/*================= MEDIA QUERIS MD ==================*/

@media screen and (max-width: 1024px) {
    .footer__links
    {
        flex-direction: column;
        gap: 1.5rem;

    }

    .footer__socials
    {
        margin-bottom: 2.6rem;
    }
}

/*================= MEDIA QUERIS SM ==================*/

@media screen and (max-width: 600px) {
    

}

#light > #footer > ul > li > a
{
    color: var(--color-dark);
}

#light > #footer > a
{
    color: var(--color-dark);
}

#light > #footer > div > a
{
    color: var(--color-dark);
}