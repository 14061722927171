.container.contact__container
{
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap:12%;
}

.contact__options
{
    display: flex;
    flex-direction: column;
    gap:1.2rem;
}

.contact__option
{
    background-color: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border:1px solid transparent;
    transition: var(--transition);
}
.contact__option a
{
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

form
{
    display: flex;
    flex-direction: column;
    gap:1.2rem
}

input, textarea
{
    width: 100%;
    border-radius: 1.2rem;
    padding: 1.5rem;
    background-color: var(--color-bg-variant);
    resize: none;
    color: var(--color-white);
    font-size: 1rem;
}



.contact__button
{
    display: flex;
    justify-content: center;
}

button
{
    font-size: 1.1rem;
}



.contact__option-icon
{
    font-size: 1.5rem;
}
.contact__option:hover
{
    background-color: transparent;

}

/*================= MEDIA QUERIS MD ==================*/

@media screen and (max-width: 1024px) {
    .container.contact__container
    {
        display: block;
        width: 90%;
    }
    
    .contact__option
    {
        margin-top: 5px;

    }
    form
    {
        margin-top: 20px;
    }
}

/*================= MEDIA QUERIS SM ==================*/

@media screen and (max-width: 600px) {
    
    .container.contact__container
    {
        display: block;
        width: 90%;
    }
    .contact__option
    {
        margin-top: 5px;

    }
    form
    {
        margin-top: 20px;
    }
}

#light > #contact > .contact__container > .contact__content > .contact__options > .contact__option
{
    background-color: rgba(136, 201, 255, 0.425);

}

#light > #contact > .contact__container > .contact__content > .contact__options > .contact__option:hover
{
    background-color: transparent;
    color: var(--color-dark);
}

#light > #contact > .contact__container > .contact__content > .contact__options > .contact__option > a
{
    color: var(--color-dark);
}

#light > #contact > .contact__container > form > input, #light > #contact > .contact__container > form > textarea
{
    color: var(--color-dark);
    background-color: rgba(136, 201, 255, 0.425);
}