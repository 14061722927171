.propos__container
{
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;

}

.propos__me
{
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg,transparent,rgb(188, 247, 244), transparent);
    display: grid;
    place-items: center;
}
.propos__me-image
{
    overflow: hidden;
    border-radius: 2rem;
    transform: rotate(10deg);
    transition: var(--transition);

}
.propos__me-image:hover
{
    transform: rotate(0deg);
    transition: var(--transition);
}
.propos__content p
{
    margin: 2rem 0 2.6rem
}
.propos__cards
{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap:1.5rem;
}
.propos__card
{
    background-color: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
    cursor: default;
}
.propos__card:hover
{
    background-color: transparent;
}

.propos__card h5
{
    font-size: 0.95rem;
}

.propos__card small
{
    font-size: 0.8rem;
    color:var(--color-light)
}


.propos__icon
{
    color:var(--color-primary);
    margin-bottom: 1rem;
    font-size: 1.4rem;
}

/*================= MEDIA QUERIS MD ==================*/

@media screen and (max-width: 1024px) {
    .propos__container
    {
        grid-template-columns: 1fr;
        gap: 0;
    }
    .propos__me
    {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    .propos__content p
    {
        margin: 1rem 0 1.5rem;
    }
}

/*================= MEDIA QUERIS SM ==================*/

@media screen and (max-width: 600px) {

    .propos__container
    {
        width: 50vh;
    }
    .propos__me
    {
        width: 65%;
        margin: 0 auto 3rem;
    }
    .propos__cards
    {
        display: block;
    }
    .propos__card
    {
        margin-top: 20px;
        
    }

    .propos__content
    {
        text-align: center;
    }


}

@media screen and (max-width: 500px) {
    .propos > h5{
        padding-top: 50px !important;
    }
}

#light > #propos > .propos__container > .propos__content > .propos__cards > .propos__card
{
    background-color: rgba(136, 201, 255, 0.425);

}

#light > #propos > .propos__container > .propos__content > .propos__cards > .propos__card:hover
{
    background-color: transparent;
}