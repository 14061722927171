nav
{
    background-color: rgba(0, 0, 0, 0.301);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index:2 ;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.9rem;
    border-radius: 3rem;
    backdrop-filter: blur(4px);
}

nav a 
{
    background-color: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;

}

nav a.active 
{
    background-color: rgba(204, 204, 204, 0.333);
    color: var(--color-white);
}

@media screen and (max-width: 500px) {

    nav > a 
    {
        background-color: transparent;
        padding: 0.7rem;
        font-size: 1.1rem;
    }

}