.experience__container
{
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    gap: 2rem;
}

.experience__container > div
{
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);

    
}

.experience__container > div:hover
{
    background-color: transparent;
    transition: var(--transition);
    cursor: default;
}

.experience__container > div > h3:hover
{
    color:var(--color-white);
}

#light > #experience > .experience__container >  div > h3:hover
{
    color:rgb(36, 35, 5);
}

#light > #experience > .experience__container >  div > h3
{
    color:var(--color-dark);
}

#light > #experience > .experience__container >  div
{
    background-color: rgba(136, 201, 255, 0.425);
}

#light > #experience > .experience__container >  div:hover
{
    background: transparent;
}



.experience__container > div h3
{
    text-align: center;
    margin-bottom:2rem;
    color: var(--color-primary);
}

.experience__content
{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
}

.experience__article
{
    display: flex;
    gap: 1rem;
}

.experience_article-icon
{
    margin-top: 6px;
    font-size: 1.7rem;
}

.experience_article-icon:hover
{
    color: var(--color-primary);
}

#light > #experience > .experience__container > div > .experience__content > .experience__article > .experience_article-icon :hover
{
    color: var(--color-white);
}

.experience__article > div > h4:hover
{
    color: var(--color-primary);
}

#light > #experience > .experience__container > div > .experience__content > .experience__article > div > h4:hover
{
    color: var(--color-white);
}

.experience__article > div > small:hover
{
    color: var(--color-primary);
}

/*================= MEDIA QUERIS MD ==================*/

@media screen and (max-width: 1024px) {
    .experience__container
    {
        grid-template-columns: 1fr;

    }
    .experience__container > div
    {
        width: 100%;
        padding: 2rem;
        margin: 0 auto;

    }

    .experience__article
    {
        display: block;
        font-size: 0.9rem;

    }
    

    .experience__content
    {
        padding: 1rem;

    }
}

/*================= MEDIA QUERIS SM ==================*/

@media screen and (max-width: 600px) {
    .experience__container
    {
        display: block;
        
    }

    .experience__container > div
    {
        margin-bottom: 2rem;

    }
    
    .experience__article
    {
        display: block;
        text-align: center;
        font-size: 0.8rem;
        
        
    }

}