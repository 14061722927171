.portfolio__container
{
    gap : 2.5rem
}
.portfolio__articles
{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap : 2.5rem
}

.portfolio__article
{
    background-color: var(--color-bg-variant);
    transition: var(--transition);

}


.portfolio__article > h3
{
    text-align: center;
}

.portfolio__article:hover
{
    transform: scale(1.2);
}

.portfolio__article-img
{
    overflow: hidden;
}

.portfolio__article h3
{
    margin: 1rem 0 1rem;
}

.portfolio_article-info
{
    display: flex;
    gap: 1.5rem;
    margin-bottom: 1rem;
    justify-content: center;
}

/*================= MEDIA QUERIS MD ==================*/

@media screen and (max-width: 1024px) {
    
}

/*================= MEDIA QUERIS SM ==================*/

@media screen and (max-width: 600px) {
    .portfolio__articles
    {
        display: block;
        width: 100%;
        margin: 0;
        
    }

    .portfolio__article
    {
        margin-top: 3rem;
        padding-bottom: 2px;
    }

    .portfolio__article:hover
    {
        transform: scale(1.1);
    }

}

#light > #portfolio > .portfolio__container > .portfolio__articles > .portfolio__article
{
    background-color: rgba(136, 201, 255, 0.479);

}

#light > #portfolio > .portfolio__container > .portfolio__articles > .portfolio__article:hover
{
    background-color: transparent;

}