header
{
    height: 85vh;
    padding-top: 4rem;
}


.header__container
{
    text-align: center;
    height: 80%;
    position: relative;
}



/* =======Info ======== */

.Info
{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* =======ReseauSociaux ======== */

.header_socials
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 6rem;
}

.header_socials::after
{
    content :'';
    width: 1px;
    height: 2rem;
    background-color: var(--color-light);
}

/* ===========IMG +++++++++++++++ */

.img-me
{
    background: linear-gradient(rgb(188, 247, 244), transparent);
    width: 22rem;
    height: 30rem;
    
    position:absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding-top: 2rem;
}

.img__deux
{
    transform: rotate(0deg);
    transition: var(--transition);
}

.img-me:hover
{
    background: linear-gradient(#3891cc, #38CCB869);
    transition: var(--transition);
}
/*============== scroll down ================*/

header > .header__container > a
{
    position: absolute;
    right: -2.3rem;
    bottom: 9rem;
    transform: rotate(90deg);
    font-weight: 200;
    font-size: 0.9rem;
}


/*================= MEDIA QUERIS MD ==================*/

@media screen and (max-width: 1024px) {
    header 
    {
        height: 68vh;
    }
}

/*================= MEDIA QUERIS SM ==================*/
@media screen and (max-width: 375px) {
    header 
    {
        height: 120vh;
    }
    .header__container > a
    {
        display: none;
    }
    .header_socials
    {
        display: none;
    }
    .img-me
    {
        display: flex;
        justify-content: center;
        left: calc(50% - 11rem);
        width: 50%;
    }
}
@media screen and (max-width: 768px) and (min-width: 376px) {
    header 
    {
        height: 90vh;
    }

    
    .header_socials
    {
        display: none;
    }

    .header__container > a
    {
        display: none;
    }
    
    .img-me
    {
        display: flex;
        justify-content: center;
        left: calc(50% - 11rem);
        width: 50%;
    }


}

#light > header > .header__container > a
{
    color: var(--color-dark);
}

#light > header > .header__container > .header_socials > a
{
    color: var(--color-dark);
}

#light > header > .header__container > .header_socials::after
{
    background-color: var(--color-dark);
}