@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*
{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root
{
    /*light*/
    --color-bg-light : rgb(255, 255, 255);
    --color-dark : rgb(1, 62, 112);
    --img-bg-light : url("./assets/light-2.svg");
    --color-primary-light:linear-gradient(#3891cc,#38CCB869);
    --color-bg-lightv : rgba(255, 243, 136, 0.425):



    /*dark*/
    --color-bg : rgb(58, 46, 163);
    --color-secondary : #4b73ca;
    --img-bg-dark : url("./assets/dark.svg");
    --color-primary-dark:linear-gradient(#0D4870FF,#04524833);
    --color-bg-variant: #4b73ca;
    --color-primary:#0eb3b3;
    --color-primary-variant:rgba(2, 26, 63, 0.4);
    --color-white : #fff;
    --color-light : rgba(231, 231, 231, 0.527);

    /*container*/
    --container-width-lg : 75%;
    --container-width-md : 86%;
    --container-width-sm : 90%;

    --transition : all 400ms ease;

}

html
{
    scroll-behavior: smooth;
}

::-webkit-scrollbar
{
    display: none;
}

body
{
    font-family: "Fira Sans", serif;
    line-height: 1.7;
}

/*================== Class generale ==============*/

.container
{
    width: var(--container-width-lg);
    margin : 0 auto;
}

h1, h2, h3, h4, h5
{
    font-weight: 500;
}

h1
{
    font-size: 2.5rem;
}

section
{
    margin-top: 8rem;
}

.switch__content
{
    padding-top: 1rem;
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
    gap: 1rem;

}

.switch__element{
    cursor: pointer;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms; 
}

label
{
    font-size: 0.8rem;
}


.text-light
{
    color : var(--color-light)
}

a
{
    color: var(--color-light);
    transition: var(--transition);
}

a:hover
{
    color: var(--color-white)
}

.btn
{
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.6rem 0.75rem;
    border-radius: 0.2rem;
    cursor: pointer;
    /* border: 1px solid var(--color-primary); */
    transition: var(--transition);
}

.btn:hover
{
    background-color: var(--color-secondary);
    color: var(--color-bg);
    border-color:transparent;
}

.btn-primary
{
    background-color: var(--color-primary);
    color: var(--color-bg);

}

img
{
    display: block;
    width: 100%;
    object-fit: cover;
}


/* light */
#light
{
    background-image: var(--img-bg-light);
    color: var(--color-dark);
    
}

#light .bg-font
{
    position: absolute;
    padding: 0.5rem 0.5rem;
    background-color: rgb(255 255 255 / #FFFFFFFF);
    border-radius: 9999px;
}

#light .text-light
{
    color : var(--color-primary-variant)
}

#light .btn
{
    width: max-content;
    display: inline-block;
    color: black;
    padding: 0.6rem 1.2rem;
    border-radius: 0.2rem;
    cursor: pointer;

    transition: var(--transition);
}

#light .btn:hover
{
    background-color: var(--color-white);
    color: var(--color-bg);
    border-color:transparent;
}

#light .btn-primary
{
    background: var(--color-primary-light);
    color: var(--color-white);

}
#light .btn-primary:hover
{
    color: white;

}


#light section  > h2, section > h5
{
    text-align: center;
}

#light section > h5
{
    color: #1a180f;
}

#light section > h2
{
    color : var(--color-dark);
    margin-bottom: 3rem;
}



/* dark */
#dark
{
    color: var(--color-white);
    background-image:var(--img-bg-dark);
}

#dark .bg-font
{
    position: absolute;
    padding: 0.5rem 0.5rem;
    background-color: rgb(255 255 255 / #071B236E);
    border-radius: 9999px;
}


#dark section  > h2, section > h5
{
    text-align: center;
    color : var(--color-light)
}




#dark section > h2
{
    color : var(--color-primary);
    margin-bottom: 3rem;
}

#dark .btn-primary
{
    background: var(--color-primary-dark);
    color: var(--color-white);

}



/*================= MEDIA QUERIS MD ==================*/

@media screen and (max-width: 1024px) {
    .container
    {
        width: var(--container-width-md);
    }

    section 
    {
        margin-top: 6rem;
        
    }
}

/*================= MEDIA QUERIS SM ==================*/

@media screen and (max-width: 600px) {
    .container
    {
        width: var(--container-width-sm);
    }

    section > h2 
    {
        margin-bottom: 2rem;
    }
}